<template>
  <div>
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="table-card mt-4">
          <v-card-title>
            <span class="accent--text">Нагрузка</span>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="tableSearch"
              class="mt-0 pt-0 table-search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
              clearable
            />
          </v-card-title>

          <v-data-table
            ref="cyclesLoadTable"
            class="base-table elevation-0"
            loading-text="Загрузка..."
            fixed-header
            hide-default-footer
            :loading="tableLoading"
            :headers="headers"
            :items="cyclesLoad"
            :sort-by="'cycleNum'"
            :sort-desc="false"
            :search="tableSearch"
            :items-per-page="-1"
          >
            <template #item.dateFrom="{ item }">
              <span>{{ item.dateFrom | formatDateReverseFromISO }}</span>
            </template>

            <template #item.dateTo="{ item }">
              <span>{{ item.dateTo | formatDateReverseFromISO }}</span>
            </template>

            <template #item.planHours="{ item }">
              <span>{{ `${item.planHours} (${item.planHours - item.planHoursAX})` }}</span>
            </template>

            <template #item.factHours="{ item }">
              <span>{{ `${item.factHours} (${item.factHours - item.planHoursAX})` }}</span>
            </template>

            <template slot="no-data">
              <div class="py-3">Нет нагрузки по циклам</div>
            </template>

            <template slot="no-results">
              <div class="py-3">Ничего не найдено</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDateReverseFromISO } from '@/scripts'

export default {
  name: 'CyclesLoadTable',

  props: {
    tableLoading: Boolean,
    cyclesLoad: {
      type: Array,
      required: true
    }
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    tableSearch: null,
    headers: [
      { text: 'Номер цикла', value: 'cycleNum', sortable: true, align: 'center' },
      { text: 'Дата начала цикла', value: 'dateFrom', sortable: false, align: 'center' },
      { text: 'Дата окончания цикла', value: 'dateTo', sortable: false, align: 'center' },
      { text: 'Плановая учебная нагрузка', value: 'planHoursAX', sortable: false, align: 'center' },
      { text: 'Плановое расписание', value: 'planHours', sortable: false, align: 'center' },
      { text: 'Фактическое расписание', value: 'factHours', sortable: false, align: 'center' }
    ]
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId'])
  },

  filters: {
    formatDateReverseFromISO
  },

  methods: {
    goToCycleLoad() {
      const params = {
        dateFrom: this.filters?.dateFrom || null,
        dateTo: this.filters?.dateTo || null
      }

      this.$router.push({ name: 'cyclesLoad', params })
    },

    setTableHeight() {
      // суммарная высота элементов на странице, кроме таблицы
      const otherElements = 318 + this.techMessageHeight

      if (this.$refs?.cyclesLoadTable) {
        this.$refs.cyclesLoadTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    }
  },

  watch: {
    chipsHeight() {
      this.setTableHeight()
    },

    filtersHeight() {
      this.setTableHeight()
    }
  }
}
</script>