<template>
  <div>
    <div class="mx-4">
      <PageTitle title="Нагрузка по циклам" />

      <v-row class="my-0">
        <v-col cols="12" class="pt-0">
          <h4 class="department">{{ departmentName }}</h4>
        </v-col>
      </v-row>

      <LoadTable
        :tableLoading="tableLoading"
        :cyclesLoad="cyclesLoad"
      />
    </div>
  </div>
</template>

<script>
import { workloadApi } from '@/api'
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title'
import LoadTable from '@/components/load/cycles/Table'

export default {
  name: 'CyclesLoad',

  metaInfo: {
    title: 'Нагрузка по циклам'
  },

  props: {
    filters: Object
  },

  components: {
    PageTitle,
    LoadTable
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.checkFiltersProps()
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    tableLoading: false,
    cyclesLoad: []
  }),

  computed: {
    ...mapGetters('department', ['departmentId', 'departmentName'])
  },

  methods: {
    async checkFiltersProps() {
      this.filters ? await this.getCyclesLoad() : await this.$router.push('/load')
    },

    async getCyclesLoad() {
      try {
        this.tableLoading = true

        const body = {
          ...this.filters,
          departmentId: this.departmentId
        }

        this.cyclesLoad = await workloadApi.getCyclesLoad(body)
      } catch (e) {
        console.log(e.status)
      } finally {
        this.tableLoading = false
      }
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>